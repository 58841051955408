<template>
  <div class="contact">
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2229.350181910643!2d-3.4012690840030055!3d56.029925380628434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887ce36caecab5d%3A0x8cf20863106f4a63!2s73A%20High%20St%2C%20Inverkeithing%20KY11%201NW%2C%20UK!5e0!3m2!1sen!2sin!4v1614792561064!5m2!1sen!2sin" height="500" style="border:0;width:100%;" allowfullscreen="" loading="lazy"></iframe> -->
    <v-container class="py-50">
      <v-row justify="center">
        <v-col cols="12" class="pb-0">
          <v-card-title class="pb-0 justify-center">Contact Us</v-card-title>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text>
            <!-- <div class="mb-3">Have a question? Request a call back now.Whether you have a question about one of our services, how we work or how we can support your specific needs please request a call back here and one of our friendly team will be in touch.</div> -->
            <form>
              <v-text-field outlined dense label="Username" name="name" v-model="name" required></v-text-field>
              <v-text-field outlined dense label="Email Address" name="email" v-model="email" required></v-text-field>
              <v-text-field outlined dense label="Phone number" name="phone" v-model="phone" required></v-text-field>
              <v-textarea outlined textarea label="Your Feedback" name="feedback" v-model="feedback" required></v-textarea>
              <v-btn block dark large @click="sendEmail()">SUBMIT</v-btn>
            </form>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text>
            <div class="mb-3">Have a question? Request a call back now.Whether you have a question about one of our services, how we work or how we can support your specific needs please request a call back here and one of our friendly team will be in touch.</div>
          </v-card-text>
          <v-card-title>Our Office</v-card-title>
          <v-card-text>
            <ul>
              <li>Address: 73 A High street,<br /> Inverkeithing Fife,<br />KY11 1NW Edinburgh Scotland UK</li>
              <li>Email: accounts@authcode.co.uk</li>
            </ul>
          </v-card-text>
          <v-card-title>Get in Touch</v-card-title>
          <v-card-text>For any opportunity or sales related activites please contact the above mentioned email.</v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <v-alert style="position: fixed;top: 60px;left: 50%;transform: translateX(-50%);" :type="alertType" v-model="alert">{{alertText}}</v-alert>
    <!-- <v-alert :type="failure" v-model="success_alert">Email sent successfully</v-alert> -->
  </div>
</template>
<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/emailjs-com@2/dist/email.min.js"></script>
<script>
  export default {
    data(){
      return {
        name: '',
        email: '',
        phone: '',
        feedback: '',
        alertType: 'success',
        alert: false,
        alertText: 'Email sent successfully'
      }
    },
    methods: {
      // contactOnSubmit: function() {
      //   if(this.name && this.email && this.phone && this.feedback){
      //     const requestParams = {
      //       name: this.name,
      //       email: this.email,
      //       phone: this.phone,
      //       feedback: this.feedback
      //     }
      //     const xhttp = new XMLHttpRequest();
      //     xhttp.onreadystatechange = function() {
      //       if (this.readyState == 4 && this.status == 200) {
      //         console.log(this.responseText);
      //       }
      //     };
      //     xhttp.open("POST", 'sendMail.php');
      //     xhttp.send(requestParams);
      //   } else{
      //     console.log('else');
      //   }
      // }
      sendEmail: function() {
        if(this.name && this.email && this.phone && this.feedback){
          var data = {
            service_id: 'service_bwyt2ln',
            template_id: 'template_vhtfx5f',
            template_params: {
                'name': this.name,
                'email': this.email,
                'phone': this.phone,
                'feedback': this.feedback
            }
          };
          const xhttp = new XMLHttpRequest();
          xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
              this.alertType = 'success';
              this.alertText = 'Email sent successfully';
              this.alert = true;
              setTimeout(()=>{this.alert = false;}, 3000)
            } else if (this.readyState == 4 && this.status != 200){
              this.alertType = 'error';
              this.alertText = 'Something went wrong. Please try again later';
              this.alert = true;
              setTimeout(()=>{this.alert = false;}, 3000)
            }
          };
          xhttp.open("POST", 'https://api.emailjs.com/api/v1.0/email/send');
          xhttp.send(data);
        } else {
          this.alertType = 'error';
          this.alertText = 'Please fill the form';
          this.alert = true;
          setTimeout(()=>{this.alert = false;}, 3000)
        }
      }
    }
  }
</script>